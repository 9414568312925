@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  /* palette */
  --main-white: #ffffff;
  --shade-white: #f5f5f5;
  --main-yellow: #ffefdd;
  --main-purple: #5f4aff;
  --main-gray: #6b6d70;
  --main-black: #151b22;

  /* Fonts */
  --base-font: Open sans, sans-serif;
  --title-font: Merriweather, serif;

  /* Font-size */
  --text-sm: 1.4rem;
  --text-price: 1.5rem;
  --text-base: 1.6rem;
  --text-body: 1.8rem;
  --text-cat: 2.4rem;
  --text-title: 3.2rem;
  --text-main-title: 8.6rem;

  /* Spacing */
  --space-1: 0.1rem;
  --space-2: 0.2rem;
  --space-4: 0.4rem;
  --space-6: 0.6rem;
  --space-8: 0.8rem;
  --space-10: 1rem;
  --space-12: 1.2rem;
  --space-16: 1.6rem;
  --space-20: 2rem;
  --space-28: 2.8rem;
  --space-36: 3.6rem;
  --space-44: 4.4rem;
  --space-52: 5.2rem;
  --space-60: 6rem;
  --space-72: 7.2rem;
  --space-84: 8.4rem;
  --space-96: 9.6rem;

  /*========== Colors ==========*/
  --hue-color: 225;
  --first-color: hsl(var(--hue-color), 48%, 35%);
  --title-color: hsl(var(--hue-color), 48%, 22%);
  --text-color: hsl(var(--hue-color), 12%, 35%);
  --body-color: hsl(var(--hue-color), 49%, 98%);
  --container-color: #fff;
}
html {
  font-size: 62.5%;
}


  /*========== Font and typography ==========*/
  /* --body-font: "Poppins", sans-serif;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem; */


/* @media screen and (min-width: 968px) {
  :root {
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
} */

/*=============== BASE ===============*/
body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

.global-accordion-container {
  padding-top: 12rem;
}

.accordion-box-title {
  font-size: 4.5rem;
  font-weight: 500;
  text-align: center;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.4));
	box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
	border: 1px solid rgba(255, 255, 255, 0.18);
	backdrop-filter: blur(50px);
	-webkit-backdrop-filter: blur(50px);
  border-radius: 15px;
  width: fit-content;
  margin: 0 auto 3.5rem;
  padding: 1.5rem;
  animation: animate-float-thin2 5s ease-in-out infinite;
}
/*=============== LAYOUT ===============*/
.accordion-container {
  margin-left: 1rem;
  margin-right: 1rem;
  min-height: 50vh;
  
}

/*=============== ACCORDION ===============*/
.accordion-box {
  display: grid;
  align-content: center;
  margin-bottom: 0;
}

.accordion__container {
  display: grid;
  row-gap: 0.75rem;
  padding: 2rem 1rem;
  margin: 4rem auto 8.4rem;
  background-color: var(--container-color);
  border-radius: 0.5rem;
  box-shadow: 0 12px 32px rgba(51, 51, 51, 0.1);
}

.accordion__title {
  font-size: var(--text-body);
  color: var(--title-color);
  font-weight: 400;
  margin-top: 0.15rem;
  transition: 0.2s;
}

.accordion__header {
  display: flex;
  column-gap: 0.5rem;
  padding: 1.25rem 1.25rem 1.25rem 1rem;
  cursor: pointer;
}

.accordion__description {
  padding: 0 1.25rem 1.25rem 3rem;
  font-size: var(--text-base);
}

.accordion__icon {
  font-size: 1.5rem;
  height: max-content;
  color: var(--title-color);
  transition: 0.3s;
}

.accordion__item {
  box-shadow: 0 2px 6px rgba(38, 38, 38, 0.1);
  background-color: var(--container-color);
  border-radius: 0.25rem;
  position: relative;
  transition: all 0.25s ease;
}

.accordion__item::after {
  content: "";
  background-color: var(--first-color);
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.25rem 0 0 0.25rem;
}

.accordion__item:nth-child(1) {
  background-color: #fff7f0;
}

.accordion__item:nth-child(1)::after {
  background-color: #ffc08a;
}

.accordion__item:nth-child(2) {
  background-color: #f0f0ff;
}

.accordion__item:nth-child(2)::after {
  background-color: #8a8aff;
}

.accordion__item:nth-child(3) {
  background-color: #fff0f3;
}

.accordion__item:nth-child(3)::after {
  background-color: #ff8aa1;
}

.accordion__item:nth-child(4) {
  background-color: #f0faff;
}

.accordion__item:nth-child(4)::after {
  background-color: #8ad8ff;
}

.accordion__content {
  overflow: hidden;
  height: 0;
  transition: all 0.25s ease;
}

/*Rotate icon and add font weight to titles*/
.accordion-open .accordion__icon {
  transform: rotate(45deg);
}

.accordion-open .accordion__title {
  font-weight: 600;
}

/*=============== MEDIA QUERIES ===============*/

/* For large devices */
@media screen and (min-width: 968px) {
  .accordion-container {
    margin-left: auto;
    margin-right: auto;
  }
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .accordion__container {
    width: 700px;
    padding: 2.5rem;
    justify-self: center;
    border-radius: 0.75rem;
  }
  .accordion__header {
    padding: 1.5rem;
  }
  .accordion__title {
    padding-right: 3.5rem;
  }
  .accordion__description {
    padding: 0 4rem 1.25rem 3.5rem;
  }
}

@media screen and (max-width: 576px) {
  .accordion-box-title {
    font-size: 2.8rem;
    font-weight: 500;
    width: 90%;
    margin: 0 auto;
    padding: 1rem;
  }
}
