*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  /* palette */
  --main-white: #ffffff;
  --shade-white: #f5f5f5;
  --main-yellow: #ffefdd;
  --main-purple: #5f4aff;
  --main-gray: #6b6d70;
  --main-black: #151b22;
}
html {
  font-size: 62.5%;
}

.page-container {
  background: url('./images/Purple-Intense.png');
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

