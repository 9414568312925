
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
* {
	font-family: 'Montserrat', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #d1e5ff;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#642bff, #ff22e6);
  border-radius: 10px;
}