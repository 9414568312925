*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

.global-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: url("./purple-bg.png");
}

.loader-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-container .ring {
    position: relative;
    width: 150px;
    height: 150px;
    margin: -30px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top: 4px solid #24ecff;
    animation: animate 4s linear infinite;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-container .ring::before {
    content: "";
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    border: 4px solid transparent;
    background: #24ecff;
    box-shadow: 0 0 0 5px #24ecff33, 
    0 0 0 10px #24ecff22, 
    0 0 0 20px #24ecff11,
    0 0 20px #24ecff, 
    0 0 50px #24ecff ;
}

.loader-container .ring:nth-child(2) {
    animation: animate2 4s linear infinite;
    animation-delay: -1s;
    border-top: 4px solid transparent;
    border-left: 4px solid #93ff2d;
}

.loader-container .ring:nth-child(2)::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: 12px;
    left: 12px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    border: 4px solid transparent;
    background: #93ff2d;
    box-shadow: 0 0 0 5px #93ff2d33, 
    0 0 0 10px #93ff2d22, 
    0 0 0 20px #93ff2d11,
    0 0 20px #93ff2d, 
    0 0 50px #93ff2d ;
}

.loader-container .ring:nth-child(3) {
    animation: animate2 4s linear infinite;
    animation-delay: -3s;
    position: absolute;
    top: -66.66px;
    border-top: 4px solid transparent;
    border-left: 4px solid #e11cf8;
}

@keyframes animate2 {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.loader-container .ring:nth-child(3)::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: 12px;
    left: 12px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    border: 4px solid transparent;
    background: #e11cf8;
    box-shadow: 0 0 0 5px #e11cf833, 
    0 0 0 10px #e11cf822, 
    0 0 0 20px #e11cf811,
    0 0 20px #e11cf8, 
    0 0 50px #e11cf8 ;
}

.loader-container p {
    position: absolute;
    color: #fff;
    font-size: 2.5rem;
    bottom: -80px;
    font-weight: 700;
    letter-spacing: 0.9rem;
}