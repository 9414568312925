*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  /* palette */
  --main-white: #ffffff;
  --shade-white: #f5f5f5;
  --main-yellow: #ffefdd;
  --main-purple: #5f4aff;
  --main-gray: #6b6d70;
  --main-black: #151b22;

  /* Font-size */
  --text-sm: 1.4rem;
  --text-price: 1.5rem;
  --text-base: 1.6rem;
  --text-body: 1.8rem;
  --text-cat: 2.4rem;
  --text-title: 3.2rem;
}
html {
  font-size: 62.5%;
}

.floating-cart {
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: #222;
}

.img-notif-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.img-notif-container img {
    width: 45px;
    margin-top: 10px;
}
.img-notif-container .notif {
    position: absolute;
    top: -2px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #f1f1f1;
    color: #222;
    border: 1px solid #000;
    font-size: 20px;
    font-weight: bolder;
}

@media screen and (max-width: 992px) {
    .floating-cart {
      display: none;
    }
}

@media (prefers-color-scheme: dark) {
  .notif, .cart-icon img {
    color: white;
  }
}