footer {
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
}
.footer-container {
  border-top: 1px solid #5f4aff;
  height: 280px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
}
.footer-container h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.footer-container p {
  font-size: 1.5rem;
  line-height: 1.2;
  margin: 1rem;
  font-weight: bold;
}

.footer-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-container ul li {
  margin: 1.3rem 0;
  font-size: 1.6rem;
}

.footer-container a {
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}

.footer-container a:hover {
  color: #5f4aff;
}

.footer-container input[type="email"] {
  border: 2px solid #5f4aff;
  border-radius: 15px;
  padding: 0.5rem;
  width: 100%;
  max-width: 400px;
  border-right: none;
}
.footer-container input[type="email"]:focus {
  outline: 1px solid #5f4aff;
  }

.footer-container form {
  display: flex;
  align-items: center;
}

.footer-container button[type="submit"] {
  background-color: #5f4aff;
  color: #fff;
  border: 2px solid #5f4aff;
  padding: 0.5rem 2.5rem;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  margin-left: -2.5rem;
}

.footer-container button[type="submit"]:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.footer-container button[type="submit"]:active {
  transform: scale(0.98);
  transition: 0.2s ease-in-out;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.social-icons a {
  margin: 0 2rem;
  color: #5f4aff;
}

.row {
  width: 100%;
  display: flex;
  margin: auto;
  padding: 1rem auto;
}

.col {
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.col-left {
  width: 30%;
}
.col-right {
  width: 30%;
}
.col-bottom {
  width: 40%;
}
.col-bottom h3 {
  margin-bottom: 1.6rem;
  color: #5f4aff;
}
.col-bottom h3, .col-bottom form {
  width: 100%;
}

.logo-footer {
  width: 150px;
  height: auto;
  color: #5f4aff;
}


@media screen and (max-width: 992px) {
  footer {
    margin-top: 0;
  }
  .footer-container {
    margin-top: 0;
    height: auto;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 1rem;
    grid-template-areas: 
      "left right"
      "bottom bottom";
  }
  .col-left {
    grid-area: left;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .col-right {
    grid-area: right;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .col-bottom {
    grid-area: bottom;
    padding: 0;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }
  .col-bottom form {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 576px) {
  .footer-container {
    margin: 0;
    padding: 0;
    height: 250px;
  }
  .footer-container p {
    font-size: 1.4rem;
    line-height: 1rem;
    margin: 1rem auto;
  }

  .footer-container ul li {
    margin: 0.5rem 0;
    font-size: 1.4rem;
  }
  .footer-container a:not(.social-icons a) {
    font-size: 1.8rem;
  }
  .logo-footer {
    width: 100px;
  }
  .col-bottom h3 {
    margin: 0;
  }
  .social-icons-mobile a {
    width: 30px;
    height: 30px;
    font-size: 2.5rem;
  }
  .social-icons-mobile a::before {
    width: 30px;
  }
}

@media screen and (max-width: 450px) {

  .footer-container {
    height: auto;
  }
  .footer-container input[type="email"] {
    width: 250px;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 
      "left"
      "right"
      "bottom";
  }
  .footer-container ul {
    text-align: center;
  }
}